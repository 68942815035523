import './Invoice.css';
import {useEffect, useRef, useState} from "react";
import fileDownload from "js-file-download";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";
import downloadBojoFile, {bojoFetch} from "./common";

function Invoice() {
    const [invoiceId, setInvoiceId] = useState(0);
    const [year, setYear] = useState(0);
    const [month, setMonth] = useState(1);
    const [weeks, setWeeks] = useState([]);
    const [oneTime, setOneTime] = useState(true);

    function fetchPdf() {

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            body: JSON.stringify({
                period: {
                    year,
                    month
                },
                invoiceId,
                weeks
            })
        };

        downloadBojoFile("/invoice/pdf", requestOptions);
    }

    const fetchTemplate = (skipMonth) => {
        const period = {};
        if (year) {
            period['year'] = year;
        }
        if (month && !skipMonth) {
            period['month'] = month;
        }
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(period)
        };

        bojoFetch("/invoice/template", requestOptions)
            .then(res => res.json())
            .then(
                result => {
                    setInvoiceId(result.invoiceId);
                    setYear(result.period.year);
                    setMonth(result.period.month);
                    setWeeks(result.weeks);
                },
                error => {
                    console.log('error', error);
                }
            );
    };

    useEffect(() => {
        if (oneTime) {
            setOneTime(false);

            fetchTemplate(true);
        }
    });

    function setHours(week, hours) {

        if (!isNaN(hours)) {

            setWeeks(weeks.map(w => {
                if (week === w.week) {
                    w.hoursWorked = hours;
                }
                return w;
            }));
        }
    }

    let weeksElement = <></>;

    if (weeks.length > 0) {
        weeksElement = <>
            <div className="invoice-week-table row">
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Week</TableCell>
                                <TableCell>Dates</TableCell>
                                <TableCell>Hours</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {weeks.map(week => (
                                <TableRow
                                    key={week.week}
                                    sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                >
                                    <TableCell>{week.week}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {week.start} - {week.end}/{month}
                                    </TableCell>
                                    <TableCell>
                                        <div className="invoice-week-table-hours-cell">
                                            <TextField className="invoice-input" value={week.hoursWorked}
                                                       onChange={e => setHours(week.week, e.target.value)}/>
                                            <Button
                                                onClick={e => setHours(week.week, +week.hoursWorked - 0.5)}>–</Button>
                                            <Button
                                                onClick={e => setHours(week.week, +week.hoursWorked + 0.5)}>+</Button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>{weeks.map(w => +w.hoursWorked).reduce((total, hours) => total + hours, 0)}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>;
    }

    return (
        <div className="Invoice">

            <div className="invoice-container">
                <div className="row">
                    <TextField id="standard-basic" label="Fakturanr" variant="standard"
                               className="invoice-input"
                               value={invoiceId} onChange={e => setInvoiceId(e.target.value)}/>
                </div>
                <div className="row">
                    <TextField id="standard-basic" label="År" variant="standard"
                               className="invoice-input"
                               value={year} onChange={e => setYear(e.target.value)}/>

                    <span className="invoice-input-month">
                        <FormControl className="invoice-input">
                            <InputLabel id="month-label">Månad</InputLabel>
                            <Select
                                labelId="month-label"
                                id="month-select"
                                value={month}
                                label="Månad"
                                onChange={e => setMonth(e.target.value)}
                            >
                                {Array(12).fill().map((element, index) => index + 1).map(m => <MenuItem key={m}
                                                                                                        value={m}>{m}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </span>
                </div>
                <Button variant="contained" onClick={e => fetchTemplate(false)}>Hämta tider</Button>
            </div>

            {weeksElement}

            <Button variant="contained" onClick={fetchPdf}>Skapa PDF</Button>
        </div>
    );
}

export default Invoice;

import './App.css';
import Invoice from "./Invoice";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Box, LinearProgress, Tab, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import Salary from "./Salary";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import TravelExpenses from "./TravelExpenses";
import PdfMerger from "./PdfMerger";
import {bojoFetch} from "./common";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});

function App() {

    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [tabValue, setTabValue] = useState(location.pathname);
    let navigate = useNavigate();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        navigate(newValue);
    };

    const [online, setOnline] = useState('Offline');
    const [accessToken, setAccessToken] = useState('');
    const [timerTrigger, setTimerTrigger] = useState(false);

    function pingBackend() {

        bojoFetch('/ping', {
            method: 'GET'
        }).then(response => {

            console.log('Pong!', response);

            if (response.status == 200) {
                setOnline('Online');

                if (isLoading) {
                    setIsLoading(false);
                }
            } else if (response.status == 401) {
                setOnline('Denied');
            } else {
                setOnline('Offline');
            }

            setTimerTrigger(!timerTrigger);

        }).catch((e) => {
            setOnline('Offline');
            console.log('Ping error', e);
            setTimerTrigger(!timerTrigger);
        });
    }

    function pingTimeout() {

        let ms = 20000;

        if (isLoading) {
            ms = 1000;
        }

        return setTimeout(pingBackend, ms);
    }

    useEffect(() => {

        console.log('useEffect');

        const timer = pingTimeout();

        return () => {
            clearTimeout(timer);
        };
    }, [timerTrigger]);


    useEffect(() => {

        console.log('useEffect - first');
        setTimerTrigger(!timerTrigger);

    }, []);

    function onAccessTokenChange(e) {
        setAccessToken(e.target.value);
        localStorage.setItem('my-key', e.target.value);
    }

    return (
        <div className="App">
            <ThemeProvider theme={darkTheme}>

                <p className="online-status">{online}</p>

                {isLoading &&
                    <div className="loading-container">

                        <div className="access-token">
                            <TextField className="access-token"
                                       autoFocus={true}
                                       value={accessToken} placeholder={"-"}
                                       onChange={onAccessTokenChange}
                            />
                        </div>
                        <Box className="loading-box">
                            <LinearProgress/>
                        </Box>
                    </div>}

                {!isLoading && <>

                    <Box sx={{width: '50%', typography: 'body1'}}>
                        <TabContext value={tabValue} ch>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Invoice" value="/"/>
                                    <Tab label="Salary" value="/salary"/>
                                    <Tab label="Travel expenses" value="/travel-expenses"/>
                                    <Tab label="Pdf merger" value="/pdf-merger"/>
                                </TabList>
                            </Box>
                        </TabContext>

                        <Routes>
                            <Route path="/" element={<Invoice/>}/>
                            <Route path="salary" element={<Salary/>}/>
                            <Route path="travel-expenses" element={<TravelExpenses/>}/>
                            <Route path="pdf-merger" element={<PdfMerger/>}/>
                        </Routes>
                    </Box>
                </>}
            </ThemeProvider>
        </div>
    );
}

export default App;

import './TravelExpenses.css';
import {Button, TextField} from "@mui/material";
import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import {useState} from "react";
import fileDownload from "js-file-download";

function TravelExpenses() {

    const [months, setMonths] = useState([]);
    const [checked, setChecked] = useState(Array(12).fill(false));
    const [salary, setSalary] = useState(46000);
    const [tax, setTax] = useState(35414);
    const [firstTime, setFirstTime] = useState(true);

    function setMonthChecked(month, value) {
        setChecked([...checked].fill(value, month, month + 1));
    }

    if (firstTime) {
        fetch(process.env.REACT_APP_API + "/salaryspecification/paydays")
            .then(res => res.json())
            .then(
                result => {
                    console.log('got it', result);
                    setMonths(result);
                    let currentMonth = new Date().getMonth();
                    setMonthChecked(currentMonth, true);
                },
                error => {
                    console.log('error', error);
                }
            );
        setFirstTime(false);
    }

    function fetchPdf() {

        let year = new Date().getFullYear();

        months.forEach(month => {
            if (checked[month.month]) {
                console.log('fetch pdf for', month);

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/pdf'
                    },
                    body: JSON.stringify({
                        salary,
                        tax,
                        payDay: year + "-" + (month.month < 9 ? '0' : '') + (month.month + 1) + "-" + month.payDay
                    })
                };

                fetch(process.env.REACT_APP_API + "/salaryspecification/pdf", requestOptions)
                    .then(response => {

                        let content = response.headers.get('Content-Disposition');
                        const filename = content ? content.split('filename=')[1] : "filename.pdf";

                        fileDownload(response.blob(), filename);
                    }).catch((e) => {
                    console.log(e)
                });
            }
        });
    }

    return (
        <div className="SalarySpecification">

            <div className="row">
                <TextField id="standard-basic" label="Bruttolön" variant="standard"
                           className="invoice-input"
                           value={salary} onChange={e => setSalary(e.target.value)}/>

                <TextField id="standard-basic" label="Skatt" variant="standard"
                           className="invoice-input"
                           value={tax} onChange={e => setTax(e.target.value)}/>
            </div>

            <div className="row">
                <Box sx={{display: 'flex', flexDirection: 'column', ml: 3}}>

                    <FormControlLabel
                        label="Alla månader"
                        control={
                            <Checkbox
                                checked={checked.filter(c => c).length === checked.length}
                                indeterminate={checked.filter(c => c).length > 0 && checked.filter(c => !c).length > 0}
                                onChange={e => setChecked([...checked].fill(e.target.checked))}
                            />
                        }
                    />

                    {months.map(m => <FormControlLabel
                        key={m.month}
                        label={m.name + " " + m.payDay + "e"}
                        control={<Checkbox checked={checked[m.month]}
                                           onChange={e => setMonthChecked(m.month, e.target.checked)}/>}
                    />)}

                </Box>

                <Button variant="contained" onClick={fetchPdf}>Skapa PDF</Button>
            </div>
        </div>
    );
}

export default TravelExpenses;

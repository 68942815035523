import fileDownload from "js-file-download";

const bojoFetch = (path, requestOptions) => {

    const accessToken = localStorage.getItem('my-key');

    if (!requestOptions) {
        requestOptions = {};
    }

    if (!requestOptions.headers) {
        requestOptions.headers = {};
    }

    requestOptions.headers = {
        ...requestOptions.headers,
        Authorization: 'jtoken ' + accessToken
    }

    return fetch(process.env.REACT_APP_API + path, requestOptions)
        .catch((e) => console.log(e));
}

const downloadBojoFile = (path, requestOptions) => {
    return bojoFetch(path, requestOptions)
        .then(response => {

            console.log('response', response);

            if (response.status === 200) {

                const content = response.headers.get('content-disposition');
                const filename = content ? content.split('filename=')[1].replaceAll('"', '') : "filename.pdf";

                response.blob().then(blob => {

                    console.log('filename>' + filename + '<');
                    fileDownload(blob, filename);
                });
            }
        });
};

export {
    bojoFetch
};
export default downloadBojoFile;
